import Vue from 'vue';
import Vuex from 'vuex';
import { apiService } from '../services/apiService';
import { translationService } from "@/services/translationService.js";
//import router from '../router/index.js';
import { config } from '../config.js';
Vue.use(Vuex);

//errorMessage, showError and showLoading can be set from component. But also gets set after/before a api-Call from store
//based on this, in app.vue ErrorNotifier or Loading-Component should be shown
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';
import VuexPersist from 'vuex-persist';
//import moment from 'moment';

const vuexLocalStorage = new VuexPersist({
  key: 'shorderpersist',
  reducer(val) {
    //clear state if not token given?
    if (val.token === null) {
      return {}
    }
    return val
  }
})

export const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    showLoading: false,
    loadingText: "Loading data. This may take a while....",
    showError: false,
    error: {},
    navbar: null,
    token: VueCookies.get('pojwt') || null,
    selectedLanguage: '',
    hideCustomerSelection: false,
    customer: null,
    availableCustomers: [],
    deliveryAdress: null,
    portfolio: null,
    partData: null,
    currency: null,
    order: [],
    config: null,
    selectedLanguageId: null,
    availableLanguages: [{ id: 'D', text: 'Deutsch', avatar: 'de.svg', locale: 'de-DE' },
    { id: 'E', text: 'English', avatar: 'gb.svg', locale: 'en-GB' }, { id: 'F', text: 'Français', avatar: 'fr.svg', locale: 'fr-FR' },
    { id: 'I', text: 'Italiano', avatar: 'it.svg', locale: 'it-IT' },
    { id: 'P', text: 'Polski', avatar: 'pl.svg', locale: 'pl-PL' }],
    calendar: {
      minDate: `${new Date().getFullYear()}-10`,
      minAvailibility: `${new Date().getFullYear()}-10-01`,
      //calendarMAx
      maxDate: `${new Date().getFullYear() + 1}-11`,
      maxAvailibility: `${new Date().getFullYear()}-11-30`,
    },
    employeeDiscount: null,
    customerSettings: {
      ftpSettings: []
    },
    dealers: []
  },
  //changed state directly
  mutations: {
    async hideError(state) {
      state.showError = false;
      state.error = {};
    },
    async showError(state, error) {
      state.showError = true;
      state.error = error;
      //Object.assign(state.error, error);
    },
    async showLoading(state, text = "Loading data. This may take a while....") {
      //console.log("showLoading")
      state.loadingText = text;
      state.showLoading = true;
      console.log("loading", state.showLoading)
    },
    async updateLoadingText(state, text = "Loading data. This may take a while....") {
      //console.log("showLoading")
      state.loadingText = text;
      //state.showLoading = true;
    },
    async hideLoading(state) {
      //console.log("hide")
      state.showLoading = false;
    },
    async dataReceived(state, response) {
      switch (response.type) {
        case 'Navbar':
          state.navbar = response.data;
          break;
        case 'AvailableCustomers':
          state.availableCustomers = response.data;
          //if only one Customer, directly set customer of state
          /*if(state.availableCustomers.length == 1){
            await this.commit('setCustomer',state.availableCustomers[0]);
            //if only one address, take that one
            if(state.customer.address.length == 1){
              await this.commit('setDeliveryAdress',state.customer.address[0]);
            }
          }*/
          //state.xrechnungId = response.data.length > 0 ? response.data : '';
          break;
      }
    },
    async setCustomer(state, customer) {
      state.customer = customer;
    },
    async setDeliveryAdress(state, adress) {
      state.deliveryAdress = adress;
    },
    async setPortfolio(state, portfolio) {
      state.portfolio = portfolio;
    },
    async setPartData(state, partData) {
      //console.log(partData);
      let cur = partData.find(x => x.sales.currency).sales.currency || 'None'
      state.currency = { symbol: cur || 'None' == "EUR" ? "€" : cur == "USD" ? "$" : cur, iso: cur };
      state.partData = partData;
    },
    async loginSuccess(state, token) {
      VueCookies.set('pojwt', token, '1d', '/', config.cookieDomain);
      state.token = token;
    },
    async logout(state) {
      VueCookies.remove('pojwt', '/', config.cookieDomain);
      delete localStorage.toBeLoadedDate;
      delete localStorage.toBeLoadedCategory;
      //window.localStorage.removeItem('shpopersist');
      state.token = null;
    },
    async config(state, config) {
      state.config = config;
      state.calendar = config.calendar;
    },
    async changeOrder(state, selection) {
      //check if date exists
      //remove all zeroQty from selection
      selection.po = selection.po.filter(x => x.qty > 0);

      let index = state.order.findIndex(x => x.date == selection.date);
      if (index == -1) {
        if (selection.po.length > 0) {
          state.order.push(selection);
        }
        return;
      }

      //remove anyways
      state.order.splice(index, 1)
      //if no po for date (length 0), remove
      if (selection.po.length == 0) {
        return;
      }
      //else overwrite
      state.order.push(selection);
      return;
    },
    async clearOrder(state) {
      state.order = [];
    },
    async setLanguage(state, language) {
      state.selectedLanguageId = language.id;
    },
    async setEmployeeDiscount(state, discount) {
      state.employeeDiscount = discount;
    },
    async resetEverythingToDefaultOnCustomerChange(state) {
      state.customer = null;
      state.deliveryAdress = null
      state.partData = null;
      state.currency = null;
      state.portfolio = null
      state.employeeDiscount = null;
      state.order = [];
    },
    async setFTPSettings(state, data) {
      state.customerSettings.ftpSettings = data;
    },
    async setDealers(state, data) {
      state.dealers = data;
    }
  },
  //actions getting called, implement business logic here if neeede
  actions: {
    async hideError({ commit }) {
      commit('hideError')
      return;
    },
    async showLoading({ commit }, text) {
      await commit('showLoading', text);
    },
    async hideLoading({ commit }) {
      console.log("hideLoading")
      await commit('hideLoading');
    },
    async login({ commit }, payload) {
      try {
        await commit('showLoading', translationService.translate("getLoadingLoginText"));
        const response = await apiService.login(payload.username, payload.password);
        await commit('loginSuccess', response.token);
        const cfgResponse = await apiService.getConfig();
        await commit('config', cfgResponse.data);
        return 200;
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async logout({ commit }) {
      await commit('logout');
      return;
    },
    async showError({ commit }, error) {
      await commit('showError', error);
      return;
    },
    async getNavbar({ commit }) {
      try {
        //await commit('showLoading');
        const response = await apiService.getNavbar();
        //success
        await commit('dataReceived', { type: 'Navbar', data: response.data });
      } catch (error) {
        await commit('showError', error);
      } finally {
        //await commit('hideLoading');
      }
    },
    async getAvailableCustomers({ commit }) {
      try {
        //console.log("GetAvailableCustomers")
        await commit('showLoading', translationService.translate("getLoadingAvailableCustomersText"));
        const response = await apiService.getCustomers();
        //success
        await commit('dataReceived', { type: 'AvailableCustomers', data: response.data });
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    //for pa_employee! they search customer Id in field, data is return then in AvailableCustomers
    async getCustomerById({ commit }, customerId) {
      try {
        //console.log("GetAvailableCustomers")
        await commit('showLoading', translationService.translate("getCustomerByIdText"));
        const response = await apiService.getCustomerById(customerId);
        //success
        await commit('dataReceived', { type: 'AvailableCustomers', data: response.data });
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async setCustomer({ commit }, customer) {
      try {
        //clear parts, clear portfolio, clear preorder
        await commit('resetEverythingToDefaultOnCustomerChange');
        await commit('setCustomer', customer);
      } catch (error) {
        await commit('setCustomer', null);
        await commit('setDeliveryAdress', null);
        await commit('showError', error);
      }
    },
    async setDeliveryAdress({ commit }, adress) {
      try {
        await commit('setDeliveryAdress', adress);
      } catch (error) {
        await commit('showError', error);
      }
    },
    async submitOrder({ commit, getters, state, dispatch }, order) {
      try {
        //EMPLOYEEEDISCOUNT
        await commit('showLoading', translationService.translate("getLoadingSubmitText"));
        const res = await apiService.submitOrder({
          agent: getters.userId, customer: { id: state.customer.customerid, address: state.deliveryAdress.addressid },
          employeeDiscount: state.employeeDiscount,
          order
        })
        await dispatch("deleteCart");
        return res
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async getPortfolio({ commit, state }) {
      if (!state.customer) {
        //clear portfolio
        await commit('setPortfolio', null);
        await commit('setCustomer', null);
        await commit('setDeliveryAdress', null);
        return;
      }
      try {
        //gets portfolio for current customerId
        await commit('showLoading', (translationService.translate("getLoadingPortfolioText")).replace("$placeholder$", state.customer.address.find((x) => x.address_type == "customer").name.name1 || 'you'));
        const res = await apiService.getPortolio(state.customer.customerid);
        await commit('setPortfolio', res.data);
        //console.log("Portfolio done")
        return true;
      } catch (error) {
        let e = error;
        e.message = `Error while retrieving Portfolio: ${e.message}`;
        await commit('showError', e);
        //reset portfolio and partIds
        await commit('setPortfolio', null);
        await commit('setPartData', null);
        return false;
      } finally {
        await commit('hideLoading');
      }
    },
    async getCart({ commit, state, dispatch }, onlyCheckExists) {
      if (!state.customer) {
        //clear portfolio
        await commit('setPortfolio', null);
        await commit('setCustomer', null);
        await commit('setDeliveryAdress', null);
        return;
      }
      try {
        //gets cart for customerId and user (token)
        await commit('showLoading', translationService.translate("getLoadingSavedCartText"));
        const res = await apiService.getCart(state.customer.customerid, onlyCheckExists);
        if (onlyCheckExists) {
          return res;
        }
        //set address
        await commit('setDeliveryAdress', state.customer.address.find(x => x.addressid == res.data.addressId));
        await dispatch("getPortfolio");
        await dispatch("getPartData");
        //set PO
        for (let p of res.data.cart) {
          await commit('changeOrder', p);
        }
      } catch (error) {
        let e = error;
        e.message = `Error while retrieving your cart: ${e.message}`;
        await commit('showError', e);
        //reset portfolio and partIds
        await commit('setPortfolio', null);
        await commit('setPartData', null);
        return false;
      } finally {
        await commit('hideLoading');
      }
    },
    async deleteCart({ commit, state }) {
      if (!state.customer) {
        //clear portfolio
        await commit('setPortfolio', null);
        await commit('setCustomer', null);
        await commit('setDeliveryAdress', null);
        return;
      }
      try {
        //gets cart for customerId and user (token)
        await commit('showLoading', translationService.translate("getDeleteSavedCartText"));
        const res = await apiService.deleteCart(state.customer.customerid);
        return res;
      } catch (error) {
        let e = error;
        e.message = `Error while retrieving your cart: ${e.message}`;
        await commit('showError', e);
        //reset portfolio and partIds
        await commit('setPortfolio', null);
        await commit('setPartData', null);
        return false;
      } finally {
        await commit('hideLoading');
      }
    },
    async getPartData({ commit, getters, state }) {
      if (!state.customer || !getters.portfolioPartIds) {
        //clear Parts
        await commit('setPartData', null);
        await commit('setCustomer', null);
        await commit('setDeliveryAdress', null);
        return;
      }
      //gets partData for current customerId, and portfolioPartIds
      try {
        //gets portfolio for current customerId
        //await commit('showLoading', (translationService.translate("getLoadingPartsText")).replace("$placeholder$", `0/${getters.portfolioPartIds.length}`));
        await commit('showLoading', (translationService.translate("getLoadingPartsText")).replace("$placeholder$", `0%`));
        //split them in chunks
        let parts = [];
        const chunkSize = 20;
        let lastChunkSize = 0
        for (let i = 0; i < getters.portfolioPartIds.length; i += chunkSize) {
          let partChunk = getters.portfolioPartIds.slice(i, i + chunkSize);
          let res = await apiService.getPartdata({ partIds: partChunk }, state.customer.customerid);
          //console.log("chunk");
          //also get Freestock
          /*for(let chunk of partChunk){
            let fs = await apiService.getFreestock(chunk);
            console.log(fs);
            freestock.push({partId: chunk})
          }*/
          lastChunkSize += partChunk.length

          //await commit('updateLoadingText', (translationService.translate("getLoadingPartsText")).replace("$placeholder$", `${lastChunkSize}/${getters.portfolioPartIds.length}`));
          await commit('updateLoadingText', (translationService.translate("getLoadingPartsText")).replace("$placeholder$", `${Math.round(lastChunkSize * 100 / getters.portfolioPartIds.length)}%`));
          parts.push(res.data);
        }
        await commit('setPartData', parts.flat());

        return true;
      } catch (error) {
        let e = error;
        e.message = `Error while retrieving partData: ${e.message}`;
        await commit('showError', e);
        //reset portfolio and partIds
        await commit('setPortfolio', null);
        await commit('setPartData', null);
        await commit('setFreestock', null);
        return false;
      } finally {
        await commit('hideLoading');
      }
    },
    async saveSelection({ commit, state }, selection) {
      try {
        //EMPLOYEEEDISCOUNT
        await commit('showLoading', translationService.translate("getLoadingSaveText"));
        await commit('changeOrder', selection);
        //save PO in DB
        await apiService.saveCart(state.customer.customerid, {
          addressId: state.deliveryAdress.addressid, order: state.order
        })
        return;
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async setUILanguage({ commit }, language) {
      await commit('setLanguage', language);
    },
    async clearOrder({ commit }) {
      await commit('clearOrder');
      //delete saved
    },
    async setEmployeeDiscount({ commit }, discount) {
      await commit('setEmployeeDiscount', discount)
    },
    async clearData({ commit }) {
      await commit('setPortfolio', null);
      await commit('setPartData', null);
      await commit('setCustomer', null);
      await commit('setDeliveryAdress', null);
    },
    async clearFTPSettings({ commit }) {
      await commit('setFTPSettings', [])
    },
    async getFTPSettings({ commit, state, dispatch }) {
      try {
        await commit('showLoading');
        const res = await apiService.getFTPSettings(state.customer.customerid);
        await commit('setFTPSettings', res.data.ftpData)
        return;
      } catch (error) {
        //if 404, clear FTP-Data
        if (error.status == 404) {
          await dispatch('clearFTPSettings');
        }
        //await commit('showError', error);
        console.log(error)
      } finally {
        await commit('hideLoading');
      }
    },
    async addFTPSetting({ commit, state }, ftpData) {
      try {
        await commit('showLoading');
        await apiService.addFTPSetting(state.customer.customerid, ftpData);
        return;
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async updateFTPSetting({ commit, state }, ftpData) {
      try {
        await commit('showLoading');
        await apiService.updateFTPSetting(state.customer.customerid, ftpData);
        return;
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async deleteFTPSetting({ commit, state }, id) {
      try {
        await commit('showLoading');
        await apiService.deleteFTPSetting(state.customer.customerid, id);
        return;
      } catch (error) {
        await commit('showError', error);
      } finally {
        await commit('hideLoading');
      }
    },
    async getDealers({ commit }) {
      try {
        await commit('showLoading');
        await commit('setDealers', [])
        const res = await apiService.getDealers();
        await commit('setDealers', res.data)
        return;
      } catch (error) {
        await commit('showError', error);
        console.log(error)
      } finally {
        await commit('hideLoading');
      }
    },
    async saveDealer({ commit }, dealer) {
      try {
        await commit('showLoading');
        /*for (let k in dealer) {
          if (dealer[k] == '')
            delete dealer[k]
        }*/
        const res = await apiService.saveDealer(dealer);
        if (res.status == 200) {
          return res.data;
        }
        return;
      } catch (error) {
        await commit('showError', error);
        console.log(error)
      } finally {
        await commit('hideLoading');
      }
    },
    async updateDealer({ commit }, dealer) {
      try {
        await commit('showLoading');
        const res = await apiService.updateDealer(dealer.data,dealer.id);
        if (res.status == 200) {
          return res.data;
        }
        return;
      } catch (error) {
        await commit('showError', error);
        console.log(error)
      } finally {
        await commit('hideLoading');
      }
    },
    async deleteDealer({ commit }, id) {
      try {
        await commit('showLoading');
        await apiService.deleteDealer(id);
        return;
      } catch (error) {
        await commit('showError', error);
        console.log(error)
      } finally {
        await commit('hideLoading');
      }
    }
  },
  //used to get state, also used as "computed props"
  getters: {
    //function so getter isn cached
    tokenNotGivenOrExpired: state => () => { return state.token == null || (Date.now() >= VueJwtDecode.decode(state.token).exp * 1000) },
    isLoggedInAsUser: state => { return state.token !== null ? VueJwtDecode.decode(state.token).user.scopes.some(x => x.startsWith('pa_')) : false },
    isLoggedInAsAdmin: state => { return state.token !== null ? VueJwtDecode.decode(state.token).user.scopes.some(x => x.startsWith('pa_employee')) : false; },
    isLoggedInAsSalesAgent: state => { return state.token !== null ? VueJwtDecode.decode(state.token).user.scopes.some(x => x.startsWith('pa_salesagent')) : false; },
    userName: state => { return state.token !== null ? decodeURIComponent(escape(VueJwtDecode.decode(state.token).user.name)) : ''; },
    userId: state => { return state.token !== null ? VueJwtDecode.decode(state.token).user.id : ''; },
    decodedJwt: state => { return state.token !== null ? VueJwtDecode.decode(state.token) : null; },
    userLanguage: state => { return state.token !== null ? VueJwtDecode.decode(state.token).user.language : ''; },
    frontendLanguage: (state, getters) => { return state.selectedLanguage ? state.selectedLanguage : getters.userLanguage; },
    links: state => {
      return state.navbar ? state.navbar : null;
    },
    portfolioPartIds: state => {
      return state.portfolio ? [...new Set(state.portfolio.flatMap(x => x.parts))] : null;
    },
    /*eslint-disable no-unused-vars */
    //matrix zubehör etc only one dimensional stuff, old
    matrixPartsOldMatrix: state => {
      function getAvailablity(path) {
        //if id not found in config, return preOrderRange
        let current = { position: -1, availibility: { id: null, from: state.calendar.minAvailibility, to: state.calendar.maxAvailibility } };
        for (let group of state.config.specialAvailabilities) {
          //if current group is in path
          if (path.includes(`${group.id}/`)) {
            //get position of current Group in path
            let position = path.indexOf(`${group.id}/`)
            //if position is greater than current, overwrite
            if (position > current.position) {
              current = { position: position, availibility: group }
            }
          }
        }
        return current;
      }

      if (!state.portfolio || !state.partData) {
        return [];
      }
      //each array entry contains root-category and the names of it
      //it also contains its parts which belongs to them and a property isHelmet
      //if isHelmet, then the parts have additional information that only helmets have
      //if not, they have just raw partdata
      //1. loop through portfolio and extract all parts and sort them in
      let mParts = [];
      /*{
        category: {id: "2020_Helme", name: "Helme"}, 
        isHelmet: true, 
        types: [
          {numeric: 415, name: 'C5 ECE', designs:[
            {numeric:918, name: 'Matt Black', sizes:{xxs...}}]}]
      }*/

      //NEW VERSION
      //Goal is to sort the parts in their portfolio.
      //helmet names and ids of sections (type, designs) must come from PORTFOLIO not from PARTS
      //TYPE = first after ROOT --> rootId
      //DESIGN = last before ID
      for (let p of state.portfolio) {
        //if the current portfolio has parts, loop through them
        if (p.parts.length > 0) {
          for (let part of p.parts) {
            //get the parts rootId
            let rootId = p.path.split('/')[0];
            //get index to see, if root already in array
            let rootIndex = mParts.findIndex(x => x.category.id == rootId)
            //category name
            let categoryDesc = state.portfolio.find(x => x.element == rootId).description;
            //is curren category a helmet-root?
            let isHelmet = categoryDesc.D.desc1.match(/Helme/i) != null || false;
            //partInfo here - if isHelmet, advanced, else basic
            let partData = state.partData.find(x => x.partid == part);

            if (!partData) { continue; }
            //basic vs advanced
            if (isHelmet) {
              //################NEW LOGIC#############
              //es wird immer nur das Elemente bearbeitet, was Teilenummern beinhaltet.
              //DIESES Element ist dann das DESIGN
              //das Element mit rootId/andereId ist der TYPE!!! das raussuchen und stuff adden
              //advanced
              //does category exists?
              if (rootIndex == -1) {
                let root = {
                  category: { id: rootId, name: [] }, isHelmet, types: []
                }
                for (let key in categoryDesc) {
                  root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
                }
                mParts.push(root);
                //update rootIndex
                rootIndex = mParts.findIndex(x => x.category.id == rootId)
              }
              //grouping
              //check if already exists a entry for that grouping
              //let typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == part.slice(0, 3));

              //wir sind hier auf portfolio ebene, wo eine Artikelnummer exisitert
              //wir wollen nun den typen der artikelnummer haben --> die typeId = rootId/id/ - also das extrahieren, dass ist dann die TypeID
              const typeId = `${p.path.split('/')[0]}/${p.path.split('/')[1]}/`;
              let typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == typeId);
              //return;
              //type not exists - init all - if it exists, do nothing
              if (typeIndex == -1) {
                //BUG FIX - change numeric to id from type, not part sliced
                let type = { numeric: typeId, name: [], designs: [] };

                //add name
                let portfolioTypeElement = state.portfolio.find(x => x.path == typeId)
                for (let key in portfolioTypeElement.description) {
                  type.name.push({ lang: key, name: (portfolioTypeElement.description[key].desc1 + portfolioTypeElement.description[key].desc2 + portfolioTypeElement.description[key].desc3 + portfolioTypeElement.description[key].desc4).trim() });
                }
                //push type
                mParts[rootIndex].types.push(type)
                //update typeIndex!
                typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == typeId);
              }
              //die designId = aktuelles ELEMENT denn es enthält artikelnummern
              const designId = p.element;
              let designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == designId);
              //check if design exists
              //let designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == part.slice(3, 6));
              //if not exists, add it
              if (designIndex == -1) {
                let design = { numeric: designId, name: [], sizes: { xxs: null, xs: null, s: null, m: null, l: null, xl: null, xxl: null, xxxl: null } }
                for (let key in p.description) {
                  design.name.push({ lang: key, name: (p.description[key].desc1 + p.description[key].desc2 + p.description[key].desc3 + p.description[key].desc4).trim() });
                }
                //push design to corrosponding type
                mParts[rootIndex].types[typeIndex].designs.push(design)
                //update designIndex!
                designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == designId)
              }
              //now add size (partId and also all prices etc.) to type and designIndex

              let partObj = {
                partId: partData.partid,
                name: [],
                img: partData.picture,
                currency: partData.sales.currency,
                prices: {
                  rrp: partData.sales.normal.price,
                  net: partData.sales.normal.net,
                  customerPrice: partData.sales.normal.customerPrice,
                  discount: partData.sales.normal.discount
                },
                freestock: partData.freestock,
                purchasable: getAvailablity(p.path)
              }

              for (let key in partData.description) {
                partObj.name.push({ lang: key, name: partData.description[key] });
              }
              let sizeId = part.slice(6, 8);

              switch (sizeId) {
                case "23":
                  //xxs
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxs = partObj;
                  break;
                case "33":
                  //xs
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xs = partObj;
                  break;
                case "43":
                  //s
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.s = partObj;
                  break;
                case "53":
                  //m
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.m = partObj;
                  break;
                case "63":
                  //l
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.l = partObj;
                  break;
                case "73":
                  //xl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xl = partObj;
                  break;
                case "83":
                  //xxl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxl = partObj;
                  break;
                case "93":
                  //xxxl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxxl = partObj;
                  break;
                default:
                  console.log("UNKNOWN PART! " + partObj)
                  break;
              }

            } else {
              //basic - only add size - also category
              let partObj = {
                partId: partData.partid,
                name: [],
                img: partData.picture,
                currency: partData.sales.currency,
                prices: {
                  rrp: partData.sales.normal.price,
                  net: partData.sales.normal.net,
                  customerPrice: partData.sales.normal.customerPrice,
                  discount: partData.sales.normal.discount
                },
                freestock: partData.freestock,
                purchasable: getAvailablity(p.path)
              }

              for (let key in partData.description) {
                partObj.name.push({ lang: key, name: partData.description[key] });
              }

              //does category exists?
              if (rootIndex == -1) {
                let root = { category: { id: rootId, name: [] }, isHelmet, parts: [] } //parts has same stuff as sizes
                for (let key in categoryDesc) {
                  root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
                }
                //add that part
                root.parts.push(partObj);
                mParts.push(root);
              } else {
                //category exists, add parts
                //if exists, add NOT
                if (!mParts[rootIndex].parts.find(x => x.partId == partObj.partId)) {
                  mParts[rootIndex].parts.push(partObj)
                }
              }
            }

          }
        }
      }
      return mParts;
    },
    matrixParts: state => {
      function getAvailablity(path) {
        //if id not found in config, return preOrderRange
        let current = { position: -1, availibility: { id: null, from: state.calendar.minAvailibility, to: state.calendar.maxAvailibility } };
        for (let group of state.config.specialAvailabilities) {
          //if current group is in path
          if (path.includes(`${group.id}/`)) {
            //get position of current Group in path
            let position = path.indexOf(`${group.id}/`)
            //if position is greater than current, overwrite
            if (position > current.position) {
              current = { position: position, availibility: group }
            }
          }
        }
        return current;
      }

      if (!state.portfolio || !state.partData) {
        return [];
      }
      //each array entry contains root-category and the names of it
      //it also contains its parts which belongs to them and a property isHelmet
      //if isHelmet, then the parts have additional information that only helmets have
      //if not, they have just raw partdata
      //1. loop through portfolio and extract all parts and sort them in
      let mParts = [];
      /*{
        category: {id: "2020_Helme", name: "Helme"}, 
        isHelmet: true, 
        types: [
          {numeric: 415, name: 'C5 ECE', designs:[
            {numeric:918, name: 'Matt Black', sizes:{xxs...}}]}]
      }*/

      //NEW VERSION
      //Goal is to sort the parts in their portfolio.
      //helmet names and ids of sections (type, designs) must come from PORTFOLIO not from PARTS
      //TYPE = first after ROOT --> rootId
      //DESIGN = last before ID
      for (let p of state.portfolio) {
        //if the current portfolio has parts, loop through them
        if (p.parts.length > 0) {
          for (let part of p.parts) {
            //get the parts rootId
            let rootId = p.path.split('/')[0];
            //get index to see, if root already in array
            let rootIndex = mParts.findIndex(x => x.category.id == rootId)
            //category name
            let categoryDesc = state.portfolio.find(x => x.element == rootId).description;
            //is curren category a helmet-root?
            let isHelmet = categoryDesc.D.desc1.match(/Helme/i) != null || false;
            //partInfo here - if isHelmet, advanced, else basic
            let partData = state.partData.find(x => x.partid == part);

            if (!partData) { continue; }
            //basic vs advanced
            if (isHelmet) {
              //################NEW LOGIC#############
              //es wird immer nur das Elemente bearbeitet, was Teilenummern beinhaltet.
              //DIESES Element ist dann das DESIGN
              //das Element mit rootId/andereId ist der TYPE!!! das raussuchen und stuff adden
              //advanced
              //does category exists?
              if (rootIndex == -1) {
                let root = {
                  category: { id: rootId, name: [] }, isHelmet, types: []
                }
                for (let key in categoryDesc) {
                  root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
                }
                mParts.push(root);
                //update rootIndex
                rootIndex = mParts.findIndex(x => x.category.id == rootId)
              }
              //grouping
              //check if already exists a entry for that grouping
              //let typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == part.slice(0, 3));

              //wir sind hier auf portfolio ebene, wo eine Artikelnummer exisitert
              //wir wollen nun den typen der artikelnummer haben --> die typeId = rootId/id/ - also das extrahieren, dass ist dann die TypeID
              const typeId = `${p.path.split('/')[0]}/${p.path.split('/')[1]}/`;
              let typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == typeId);
              //return;
              //type not exists - init all - if it exists, do nothing
              if (typeIndex == -1) {
                //BUG FIX - change numeric to id from type, not part sliced
                let type = { numeric: typeId, name: [], designs: [] };

                //add name
                let portfolioTypeElement = state.portfolio.find(x => x.path == typeId)
                for (let key in portfolioTypeElement.description) {
                  type.name.push({ lang: key, name: (portfolioTypeElement.description[key].desc1 + portfolioTypeElement.description[key].desc2 + portfolioTypeElement.description[key].desc3 + portfolioTypeElement.description[key].desc4).trim() });
                }
                //push type
                mParts[rootIndex].types.push(type)
                //update typeIndex!
                typeIndex = mParts[rootIndex].types.findIndex(x => x.numeric == typeId);
              }
              //die designId = aktuelles ELEMENT denn es enthält artikelnummern
              const designId = p.element;
              let designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == designId);
              //check if design exists
              //let designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == part.slice(3, 6));
              //if not exists, add it
              if (designIndex == -1) {
                let design = { numeric: designId, name: [], sizes: { xxs: null, xs: null, s: null, m: null, l: null, xl: null, xxl: null, xxxl: null } }
                for (let key in p.description) {
                  design.name.push({ lang: key, name: (p.description[key].desc1 + p.description[key].desc2 + p.description[key].desc3 + p.description[key].desc4).trim() });
                }
                //push design to corrosponding type
                mParts[rootIndex].types[typeIndex].designs.push(design)
                //update designIndex!
                designIndex = mParts[rootIndex].types[typeIndex].designs.findIndex(x => x.numeric == designId)
              }
              //now add size (partId and also all prices etc.) to type and designIndex

              let partObj = {
                partId: partData.partid,
                name: [],
                img: partData.picture,
                currency: partData.sales.currency,
                prices: {
                  rrp: partData.sales.normal.price,
                  net: partData.sales.normal.net,
                  customerPrice: partData.sales.normal.customerPrice,
                  discount: partData.sales.normal.discount
                },
                freestock: partData.freestock,
                purchasable: getAvailablity(p.path)
              }

              for (let key in partData.description) {
                partObj.name.push({ lang: key, name: partData.description[key] });
              }
              let sizeId = part.slice(6, 8);

              switch (sizeId) {
                case "23":
                  //xxs
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxs = partObj;
                  break;
                case "33":
                  //xs
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xs = partObj;
                  break;
                case "43":
                  //s
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.s = partObj;
                  break;
                case "53":
                  //m
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.m = partObj;
                  break;
                case "63":
                  //l
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.l = partObj;
                  break;
                case "73":
                  //xl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xl = partObj;
                  break;
                case "83":
                  //xxl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxl = partObj;
                  break;
                case "93":
                  //xxxl
                  mParts[rootIndex].types[typeIndex].designs[designIndex].sizes.xxxl = partObj;
                  break;
                default:
                  console.log("UNKNOWN PART! " + partObj)
                  break;
              }

            } else {
              //basic - only add size - also category

              let partObj = {
                partId: partData.partid,
                name: [],
                img: partData.picture,
                currency: partData.sales.currency,
                prices: {
                  rrp: partData.sales.normal.price,
                  net: partData.sales.normal.net,
                  customerPrice: partData.sales.normal.customerPrice,
                  discount: partData.sales.normal.discount
                },
                freestock: partData.freestock,
                purchasable: getAvailablity(p.path)
              }

              for (let key in partData.description) {
                partObj.name.push({ lang: key, name: partData.description[key] });
              }

              //does category exists?
              if (rootIndex == -1) {
                let root = { category: { id: rootId, name: [] }, isHelmet, parts: [] } //parts has same stuff as sizes
                for (let key in categoryDesc) {
                  root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
                }
                //add that part
                root.parts.push(partObj);
                mParts.push(root);
              } else {
                //category exists, add parts
                //if exists, add NOT
                if (!mParts[rootIndex].parts.find(x => x.partId == partObj.partId)) {
                  mParts[rootIndex].parts.push(partObj)
                }
              }
            }

          }
        }
      }
      //was steht in root drin? was in mParts?
      //console.log(root);
      //console.log(mParts);
      return mParts;
    },
    sortedOrderGoals: state => {
      return state.customer.sales.orderGoals.sort((a, b) => b.target - a.target);
    },
    //PO in Cart without the PO Discount
    inCartOrderSumWithoutOrderDiscount: state => {
      //loop through all, sum everything up.
      let sum = 0;
      for (let order of state.order) {
        for (let orderedParts of order.po) {
          sum += parseInt(orderedParts.qty) * (state.partData.find(x => x.partid == orderedParts.partId).sales.preorder.customerPrice || 0);
        }
      }
      return Math.round(sum * 1e2) / 1e2;
    },
    //reached PO goal of customer
    inCartReachedOrderGoal: (state, getters) => {
      return getters.sortedOrderGoals.find(x => x.target <= getters.inCartOrderSumWithoutOrderDiscount) || { target: 0, discount: 0 };
    },
    //returns language ID following priority: users selectedLanguage --> userLanguage from token --> Fallback Englisch
    UILanguage: (state, getters) => {
      //check if userLange exists, if not, fallback englisch
      const tokenLanguage = state.availableLanguages.find(x => x.id == getters.userLanguage);
      const selectedLanguage = state.availableLanguages.find(x => x.id == state.selectedLanguageId);
      //const fallBackLanguage = state.availableLanguages.find(x => x.id == 'E');
      //return selectedLanguage || tokenLanguage || fallBackLanguage;
      return selectedLanguage || tokenLanguage
    },
    availableEmployeeDiscounts: (state, getters) => {
      return (getters.isLoggedInAsAdmin || getters.isLoggedInAsSalesAgent) ? state.config.employeeDiscounts : [];
    },
    inCartReachedOrderGoalOrEmployeeDiscount: (state, getters) => {
      return state.employeeDiscount ? { target: 0, discount: state.employeeDiscount } : getters.inCartReachedOrderGoal;
    },
    partDataNormalized: (state) => {
      let pd = [];
      if (state.partData) {
        for (const part of state.partData) {
          let p = part;
          //method with partData which gets freestock and stores this into variable, fsParts gets it from method
          //part.freestock = { stock: part.freestock, color: part.freestock <= 0 ? 'orange' : 'green'}
          p.name = [];
          p.img = part.picture;
          for (let desc in p.description) {
            p.name.push({ lang: desc, name: (p.description[desc].desc1 + ' ' + p.description[desc].desc2 + ' ' + p.description[desc].desc3 + ' ' + p.description[desc].desc4).trim() })
          }
          //root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
          pd.push(p);
        }
      }
      return pd;
    },
    ftpSettings: (state) => {
      return state.customerSettings.ftpSettings;
    },
    dealers: (state) => {
      return state.dealers;
    }
    /*fsParts: (state) => {
      let fsPs = [];
      if(state.partData){
        for (const part of state.partData) {
          //method with partData which gets freestock and stores this into variable, fsParts gets it from method
          part.freestock = { stock: part.freestock, color: part.freestock <= 0 ? 'orange' : 'green'}
          part.name = [];
          part.img = part.picture;
          for(let desc in part.description){
            part.name.push({ lang: desc, name: (part.description[desc].desc1 + ' ' + part.description[desc].desc2 + ' ' +  part.description[desc].desc3 + ' ' +  part.description[desc].desc4).trim() })
          }
          //root.category.name.push({ lang: key, name: (categoryDesc[key].desc1 + categoryDesc[key].desc2 + categoryDesc[key].desc3 + categoryDesc[key].desc4).trim() });
          fsPs.push(part);
        }
      }
      return fsPs;
    },*/
  }
});