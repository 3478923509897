import { store } from '../store/store.js';
import { config } from '../config.js';
import { saveAs } from 'file-saver';

//import router from '../router/index.js';
const _APIURL = config.APIURL;


export const apiService = {
  login,
  getNavbar,
  getCustomers,
  submitOrder,
  getPortolio,
  getPartdata,
  getConfig,
  saveCart,
  getCart,
  deleteCart,
  getCustomerById,
  getFreestock,
  getFTPSettings,
  addFTPSetting,
  updateFTPSetting,
  deleteFTPSetting,
  getDealers,
  saveDealer,
  deleteDealer,
  updateDealer
}

async function handleResponse(response) {
  const body = await response.text();
  const jsonBody = JSON.parse(body);

  if (!response.ok) {
    //just show error message and do not push back
    /*if (response.status == 401) {
      await store.dispatch('logout');
      if (router.currentRoute.fullPath !== '/login') {
        await router.push('/login');
      }
    }*/
    if (response.status == 401) {
      window.location.href = `${config.loginUrl}/login?returnUrl=${encodeURIComponent(window.location.origin)}`;
    }
    let error = new Error(jsonBody.message || '');
    error = jsonBody;
    throw error;
  } else {
    //OK
    return jsonBody;
  }
}
/*eslint-disable no-unused-vars */
async function handleFileResponse(response) {

  if (!response.ok) {
    await handleResponse(response);
    return;
  }

  const blob = await response.blob();
  const filename = response.headers.get('content-disposition')
    .split(';')
    .find(n => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .replace(/"/g, '');
  saveAs(blob, filename);
}

/*function getQueryString(obj) {
  let string = '';
  for (let key in obj) {
    if (obj[key]) {
      string += string ? `&${key}=${obj[key]}` : `?${key}=${obj[key]}`;
    }
  }

  return string;
}*/

/*
async function getLogons(user, machine, amount) {

  const request = {
    method: 'GET',
    headers: {
      //'Authorization': 'Bearer ' + store.state.token,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    }
  }
  const queryString = getQueryString({ user, machine });
  const response = await fetch(`${_APIURL}/it/v1/logon/latest/${amount}${queryString}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}*/

async function login(username, password) {
  //let data = { username, password, strategy: 'ldap' };
  let data = { username, password };

  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }

  const response = await fetch(`${_APIURL}/order/login`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getCustomers() {

  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token, },
  }

  const response = await fetch(`${_APIURL}/order/v1/customer/`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getCustomerById(customerId) {

  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token, },
  }

  const response = await fetch(`${_APIURL}/order/v1/customer/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;

}

async function submitOrder(preorder) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(preorder)
  }

  const response = await fetch(`${_APIURL}/order/v1/submit`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function saveCart(customerId, data) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(data)
  }

  const response = await fetch(`${_APIURL}/order/v1/cart/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getCart(customerId, onlycheckexists) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    //body: JSON.stringify(data)
  }

  const response = await fetch(`${_APIURL}/order/v1/cart/${customerId}?onlycheckexists=${onlycheckexists}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function deleteCart(customerId) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    //body: JSON.stringify(data)
  }

  const response = await fetch(`${_APIURL}/order/v1/cart/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}


async function getPortolio(customerId) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${_APIURL}/order/v1/portfolio/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getConfig() {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${_APIURL}/order/v1/config`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getPartdata(partIds, customerId) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(partIds)
  }

  const response = await fetch(`${_APIURL}/order/v1/parts/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

//NOT USED ANYMORE - BE AWARE - THERE IS V2 NOW!
async function getFreestock(partId) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${_APIURL}/order/v1/parts/freestock/${partId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getFTPSettings(customerId) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${_APIURL}/order/v1/customer/ftp/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function addFTPSetting(customerId, ftpData) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(ftpData)
  }

  const response = await fetch(`${_APIURL}/order/v1/customer/ftp/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function updateFTPSetting(customerId, ftpData) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(ftpData)
  }

  const response = await fetch(`${_APIURL}/order/v1/customer/ftp/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function deleteFTPSetting(customerId, id) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(id)
  }
  const response = await fetch(`${_APIURL}/order/v1/customer/ftp/${customerId}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getDealers() {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${config.DEALERAPI}/dealerlocator/v1/dealer/`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function saveDealer(dealer) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(dealer)
  }

  const response = await fetch(`${config.DEALERAPI}/dealerlocator/v1/dealer/`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function updateDealer(dealer, id) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
    body: JSON.stringify(dealer)
  }

  const response = await fetch(`${config.DEALERAPI}/dealerlocator/v1/dealer/${id}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function deleteDealer(id) {
  //let data = { username, password, strategy: 'ldap' };
  const request = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + store.state.token },
  }

  const response = await fetch(`${config.DEALERAPI}/dealerlocator/v1/dealer/${id}`, request);
  const parsed = await handleResponse(response);
  return parsed;
}

async function getNavbar() {
  const request = {
    method: 'GET',
    /*headers: {
      //'Authorization': 'Bearer ' + store.state.token,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    }*/
  }
  const response = await fetch(`${_APIURL}/frontend/v1/navigation`, request);
  const parsed = await handleResponse(response);
  return parsed;
}